<script lang="ts" setup>
    import { OffsetGroup } from '~/data/OffsetGroup'

    defineProps<{
        block: AccordionsBlock
    }>()

    const activeItem = ref(undefined)
    const offset = new OffsetGroup()
    const interactedWith = ref(false)

    let timeout: NodeJS.Timeout

    watch(activeItem, () => {
        if (timeout) {
            // clear any pending timeouts
            clearTimeout(timeout)
        }

        const activeItemTitle = document.querySelector('.accordion-item.active > h2')

        if (!activeItemTitle) {
            return
        }

        timeout = setTimeout(() => {
            const offset = activeItemTitle.getBoundingClientRect().top
            console.log(offset)

            if (offset > 80) {
                return
            }

            window.scrollTo({
                top: window.scrollY + offset - 80,
                behavior: 'smooth',
            })
        }, 400)
    })
</script>

<template>
    <div class="layout-grid block-gap-y">
        <div
            class="accordion-items"
            :class="{ [offset.classes]: true, 'interacted-with': interactedWith }"
            @click="interactedWith = true"
        >
            <BlockAccordion
                v-for="(accordion, i) in block.accordions"
                :block="accordion"
                :index="i"
                :total="block.accordions.length"
                v-model:activeItem="activeItem"
                data-aos="fade-up"
                :data-aos-delay="i * 100"
            />
        </div>
    </div>
</template>

<style>
    .accordion-item + .accordion-item {
        @apply !border-t-0;
    }

    .accordion-item:last-child .accordion-content {
        @apply mb-0;
    }

    /* hack to prevent AOS from fading out accordion items when they expand */
    .interacted-with .accordion-item {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
</style>
